<template>
  <article class="art platform">
    <div class="art-section standard container md">
      <div class="flex-column">
        <div class="art-title">
          <h3 class="art-title__main">Retail Transformation</h3>
        </div>
      </div>
      <div class="flex-row">
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/d59d4bb0950372a6c47d834105129c2d.jpg')}) lightgray center center no-repeat`,
        }">
          <p>Human Connection for the Digital Age</p>
        </div>
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/e6a1abef5f8680b31ff8b1874076e7a0.jpg')}) lightgray center center no-repeat`,
        }">
          <p>Disrupting the Digital Disruption</p>
        </div>
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/cd7bb10dd462beadb04a671ee582e6b2.jpg')}) lightgray center center no-repeat`,
        }">
          <p>The Value of Zero Party Data</p>
        </div>
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/7dcd62184df4bea77833a335199b12d4.jpg')}) lightgray center center no-repeat`,
        }">
          <p>Rejuvenated Human Capital</p>
        </div>
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/5baee94d8fc0596a18a5912438a612ec.jpg')}) lightgray center center no-repeat`,
        }">
          <p>Generative AI - an evolutionary tale</p>
        </div>
        <div class="item"
             :style="{
          'background': `linear-gradient(0deg, rgba(13, 13, 19, 0.40) 0%, rgba(13, 13, 19, 0.40) 100%), url(${require('@/assets/img/home/retailTransformation/a887b360c4e3066c8cade3bd6d109659.jpg')}) lightgray center center no-repeat`,
        }">
          <p>What are SSOTs?</p>
        </div>

      </div>
    </div>
  </article>
</template>

<script>
export default {
  name:       'Section10',
  components: {}
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.flex-column {
  align-items: center;
}

.flex-row {
  gap: @gap;

  @media (max-width: @screen-tablet) {
    flex-wrap: wrap;
  }
}

.item {
  border-radius: (@gap/2);
  overflow: hidden;
  height: 150px;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: auto 100%!important;
  .animate(@duration*1s);

  &:hover {
    background-size: auto 125% !important;
  }

  p {
    font: 600 16px/120% @fontFamily;
    color: #F6F5FA;
    padding: (@gap/2);
  }

  @media (max-width: @screen-tablet) {
    background-size: cover !important;
  }
}


</style>
