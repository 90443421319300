<template>
  <section class="section3">
    <div class="container md">
      <div class="wrap">
        <div class="media">
          <div class="phone">
            <img src="@/assets/img/home/device/4.jpg" alt="">
          </div>
        </div>
        <div class="content">
          Our proprietary and innovative intelligence platform re-establishes
          the economic value of human connections, by integrating:

          <ul class="list">
            <li>Omnichannel communication</li>
            <li>Multi-model Generative AI</li>
            <li>Brand-tuned LLMs</li>
            <li>Customer-Centric CRM</li>
            <li>Livestreaming (voice, video, text)</li>
            <li>Digital commerce APIs</li>
            <li>Predictive intelligence</li>
            <li>Sales enablement</li>
            <li>Dynamic analytics</li>
            <li>Financial reporting</li>
          </ul>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section3',
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section3 {
  position: relative;
  width: 100%;
  //overflow: hidden;
  padding: @gap*1 0 @gap*4;
  //background: #0d063e url('@/assets/img/home/section-2.png') center center no-repeat;
  //background-size: cover;
  //background-blend-mode: overlay;
  //font: 500 32px/150% @fontFamily;
  color: #F6F5FA;

  @media (max-width: @screen-tablet) {
    padding: @gap*3 0;
    font: 500 24px/150% @fontFamily;
  }
  @media (max-width: @screen-mobile_sm) {
    padding: @gap*2 0;
    font: 500 16px/150% @fontFamily;
  }
}

.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: @gap*2;
  padding: @gap*2.5 0;

  &:before {
    content: '';
    position: absolute;
    right: @gap*(-2.8);
    top: 0;
    height: 100%;
    width: 120vw;
    background: linear-gradient(90deg, #0C053D 30%, #281885 50%, #3F2AB0 100%);
  }

  @media (max-width: @screen-mobile_sm) {
    flex-direction: column-reverse;
  }
}

.content {
  position: relative;
  width: min(860px, 100%);
}

.list {
  margin-top: 1rem;
  font-size: 75%;
  column-count: 2;
  list-style-type: circle;

  li {
    margin-left: 20px;
  }

  @media (max-width: @screen-tablet) {
    column-count: 1;
  }
}

.media {
  flex-grow: 2;
  @media (max-width: @screen-mobile_sm) {
    width: 50%;
  }
}

.phone {
  position: relative;
  margin: -50% 0;
  width: 227px;

  @media (max-width: @screen-mobile_sm) {
    max-width: 100%;
    margin: 0;
  }
}


</style>
