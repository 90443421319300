<template>
  <section class="section9">
    <div class="container md">
      <div class="wrap">

        <div class="content">
          <div class="flex-column">
          <p>
            Umego is spearheading a retail renaissance, using our
            multifaceted AI-driven customer intelligence platform as a
            sponge, extracting intelligence to understand consumer needs
            and behavior at depths unknown since the death of the corner
            store. The intelligence we activate is local, personal, forwardlooking, long-tail customer value. It’s the new oil.
          </p>
          </div>
        </div>

        <div class="media">
          <div class="phone">
            <img src="@/assets/img/home/device/8.jpg" alt="">
          </div>
          <div class="phone">
            <img src="@/assets/img/home/device/10.jpg" alt="">
          </div>
          <div class="phone">
            <img src="@/assets/img/home/device/4.jpg" alt="">
          </div>

<!--          <div class="device">
            <img src="@/assets/img/home/phone.png" alt="">
          </div>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section9'
}
</script>

<style scoped lang="less">
@import (reference) "src/assets/css/style";

.section9 {
  position: relative;
  width: 100%;
  //overflow: hidden;
  padding: @gap*1 0 @gap*4;
  //background: #0d063e url('@/assets/img/home/section-2.png') center center no-repeat;
  //background-size: cover;
  //background-blend-mode: overlay;
  font: 300 24px/125% @fontFamily;
  color: #F6F5FA;

  @media (max-width: @screen-tablet) {
    padding: @gap*3 0;
    font: 500 18px/150% @fontFamily;
  }
  @media (max-width: @screen-mobile_sm) {
    padding: @gap*2 0;
    font: 500 16px/150% @fontFamily;
  }
}

.wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: @gap*2;
  padding: @gap*2.5 0;

  &:before {
    content: '';
    position: absolute;
    right: @gap*(-2.8);
    top: 0;
    height: 100%;
    width: 120vw;
    background: linear-gradient(90deg, #0C053D 0%, #251C8A 100%);
  }

  @media (max-width: @screen-tablet) {
    flex-direction: column;
  }
}

.content {
  position: relative;
  width: min(860px, 100%);
}

.media {
  flex-grow: 2;
  display: flex;
  gap: .5rem;
  align-items: center;
  height: 0;
  @media (max-width: @screen-tablet) {
    height: auto;
  }
  @media (max-width: @screen-mobile_sm) {
    flex-direction: column;
  }
}

.phone {
  position: relative;
  width: 190px;
  height: 397px;

  @media (max-width: @screen-mobile_sm) {
    width: 50%;
    height: auto;
  }
}


</style>
